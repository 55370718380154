
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './http-interceptors/authInterceptor';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { CanActivateRouteGuard } from './can-activate-route.guard';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './shared/components/main-menu/main-nav/main-nav.component';
import { MaterialModule } from './material/material.module'
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { MatConfirmDialogBoxComponent } from './shared/components/mat-confirm-dialog-box/mat-confirm-dialog-box.component';
import { MatSelectAllComponent } from './shared/components/mat-select-all/mat-select-all.component';
import { CorporatebrandlistComponent } from './components/organization/corporatebrand/corporatebrandlist/corporatebrandlist.component';
import { SubindexComponent } from './components/organization/subindex/subindex/subindex.component';
import { SubindexlistComponent } from './components/organization/subindex/subindexlist/subindexlist.component';
import { SubindexformComponent } from './components/organization/subindex/subindexform/subindexform.component';
import { DepartmentlistComponent } from './components/organization/department/departmentlist/departmentlist.component';
import { DepartmentComponent } from './components/organization/department/department/department.component';
import { DepartmentformComponent } from './components/organization/department/departmentform/departmentform.component';
import { GarmentgroupComponent } from './components/organization/garmentgroups/garmentgroup/garmentgroup.component';
import { GarmentgroupformComponent } from './components/organization/garmentgroups/garmentgroupform/garmentgroupform.component';
import { GarmentgrouplistComponent } from './components/organization/garmentgroups/garmentgrouplist/garmentgrouplist.component';
import { IndexgroupComponent } from './components/organization/indexgroup/indexgroup/indexgroup.component';
import { IndexgroupformComponent } from './components/organization/indexgroup/indexgroupform/indexgroupform.component';
import { IndexgrouplistComponent } from './components/organization/indexgroup/indexgrouplist/indexgrouplist.component';
import { CorporatebrandComponent } from './components/organization/corporatebrand/corporatebrand/corporatebrand/corporatebrand.component';
import { CorporatebrandformComponent } from './components/organization/corporatebrand/corporatebrandform/corporatebrandform.component';
import { DepartmentsubindexlistComponent } from './components/organization/department/subindex/subindexlist/subindexlist.component';
import { DepartmentsubindexformComponent } from './components/organization/department/subindex/subindexform/subindexform.component';
import { IndexComponent } from './components/organization/index/index/index.component';
import { IndexformComponent } from './components/organization/index/indexform/indexform.component';
import { IndexlistComponent } from './components/organization/index/indexlist/indexlist.component';
import { DepartmentcustomcustomergroupformComponent } from './components/organization/department/customcustomergroup/customcustomergroupform/customcustomergroupform.component';
import { DepartmentcustomcustomergrouplistComponent } from './components/organization/department/customcustomergroup/customcustomergrouplist/customcustomergrouplist.component';
import { DepartmentmarkupgrouplistComponent } from './components/organization/department/markupgroup/markupgrouplist/markupgrouplist.component';
import { DepartmentmarkupgroupformComponent } from './components/organization/department/markupgroup/markupgroupform/markupgroupform.component';
import { CustomergroupComponent } from './components/organization/customergroup/customergroup/customergroup.component';
import { CustomergroupformComponent } from './components/organization/customergroup/customergroupform/customergroupform.component';
import { CustomergrouplistComponent } from './components/organization/customergroup/customergrouplist/customergrouplist.component';
import { DivisionlistComponent } from './components/organization/division/divisionlist/divisionlist.component';
import { DivisionComponent } from './components/organization/division/division/division.component';
import { DivisionformComponent } from './components/organization/division/divisionform/divisionform.component';
import { SectionComponent } from './components/organization/section/section/section.component';
import { SectionformComponent } from './components/organization/section/sectionform/sectionform.component';
import { SectionlistComponent } from './components/organization/section/sectionlist/sectionlist.component';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MarkupgroupComponent } from './components/organization/markupgroup/markupgroup/markupgroup.component';
import { MarkupgroupformComponent } from './components/organization/markupgroup/markupgroupform/markupgroupform.component';
import { MarkupgrouplistComponent } from './components/organization/markupgroup/markupgrouplist/markupgrouplist.component';
import { CustomDatePipe } from '../app/pipes/CustomDatePipe'
import { CustDatePipe } from '../app/pipes/CustDatePipe'
import { SeasonDatePipe } from '../app/pipes/SeasonDatePipe'
import { CustomeDateValidator } from 'src/app/shared/components/customedatevalidator/customedatevalidator'
import { AccountService } from './shared/services/account.service';
import { ChannelComponent } from './components/market/channel/channel/channel.component';
import { ChannelformComponent } from './components/market/channel/channelform/channelform.component';
import { ChannellistComponent } from './components/market/channel/channellist/channellist.component';
import { EnvServiceProvider } from '../app/shared/services/env.service.provider';
import { GeographicalcountryformComponent } from './components/country/geographicalcountry/geographicalcountryform/geographicalcountryform.component';
import { GeographicalcountryComponent } from './components/country/geographicalcountry/geographicalcountry/geographicalcountry.component';
import { GeographicalcountrylistComponent } from './components/country/geographicalcountry/geographicalcountrylist/geographicalcountrylist.component'
import { FiscalcountryformComponent } from './components/country/fiscalcountry/fiscalcountryform/fiscalcountryform.component';
import { FiscalcountryComponent } from './components/country/fiscalcountry/fiscalcountry/fiscalcountry.component';
import { FiscalcountrylistComponent } from './components/country/fiscalcountry/fiscalcountrylist/fiscalcountrylist.component'
import { ConfigurationService } from './shared/services/configuration.service';
import { ProducttypeandgroupComponent } from './components/country/fiscalcountry/vatexception/producttypeandgroup/producttypeandgroup.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS } from 'src/app/shared/const/constant';
import { AppDateAdapter } from 'src/app/shared/components/appdateadapter/appdateadapter';
import { VatComponent } from './components/country/fiscalcountry/vat/vat/vat.component';
import { CurrencyComponent } from './components/country/fiscalcountry/currencies/currency/currency.component';
import { CurrencyMasterComponent } from './components/currency/currency/currency/currency.component';
import { GbccostComponent } from './components/country/fiscalcountry/gbccost/gbccost/gbccost.component';
import { VatexceptionComponent } from './components/country/fiscalcountry/vatexception/vatexception/vatexception.component';
import { MyFilterPipe } from '../app/pipes/MyFilterPipe'
import { MultiselectComponent } from './components/organization/garmentgroups/multiselect/multiselect.component';
import { ApplicationInsightService } from './shared/services/application-insight.service';
import { EarlierplanningComponent } from './components/organization/earlierplanning/earlierplanning/earlierplanning.component';
import { EarlierplanninglistComponent } from './components/organization/earlierplanning/earlierplanninglist/earlierplanninglist.component';
import { EarlierplanningformComponent } from './components/organization/earlierplanning/earlierplanningform/earlierplanningform.component';
import { EarlierplanningdepartmentComponent } from './components/organization/earlierplanning/earlierplanningdepartment/earlierplanningdepartment/earlierplanningdepartment.component';
import { SouthernhemisphereComponent } from './components/organization/southernhemisphere/southernhemisphere/southernhemisphere.component';
import { SouthernhemisphereformComponent } from './components/organization/southernhemisphere/southernhemisphereform/southernhemisphereform.component';
import { SouthernhemispherelistComponent } from './components/organization/southernhemisphere/southernhemispherelist/southernhemispherelist.component';
import { SouthernhemispheredepartmentComponent } from './components/organization/southernhemisphere/southernhemispheredepartment/southernhemispheredepartment.component';
import { SouthernhemispherdepartmentlistComponent } from './components/organization/southernhemisphere/southernhemispherelist/southernhemispheredepartmentlist';
import { nextlinebrPipe } from './pipes/nextlinebr-pipe.pipe';
import { LoaderInterceptor } from "../app/http-interceptors/loader.interceptor";
import { UserAccess } from "../app/models/userAccess";
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { DepartmentviewComponent } from './components/organization/department/departmentlist/departmentview.component';
import { IndexGroupviewComponent } from './components/organization/indexgroup/indexgrouplist/indexgroupview.component';
import { IndexviewComponent } from './components/organization/index/indexlist/indexview.component';
import { SubIndexViewComponent } from './components/organization/subindex/subindexlist/subindexview';
import { GarmentGroupComponent } from './components/organization/garmentgroups/garmentgrouplist/garmentgroupview.component';
import { MarkupGroupViewComponent } from './components/organization/markupgroup/markupgrouplist/markupgroupview.component';
import { CurrencyformComponent } from './components/currency/currencyform/currencyform/currencyform.component';
import { CurrencylistComponent } from './components/currency/currencylist/currencylist/currencylist.component';
import { CorporateviewComponent } from './components/organization/corporatebrand/corporatebrandlist/corporatebrandView.component';
import { CurrencyviewComponent } from './components/currency/currencylist/currencylist/currencyviewComponent';
import { customerGroupviewComponent } from './components/organization/customergroup/customergrouplist/customergroupview.component';
import { disvisonviewComponent } from './components/organization/division/divisionlist/divisonview.component';
import { SectionviewComponent } from './components/organization/section/sectionlist/sectionview.componet';
import { AgGridModule } from 'ag-grid-angular';
import { ProuctbrandcomponentComponent } from './components/organization/productbrand/productbrandlist/prouctbrandcomponent.component';
import { ProductbrandformComponent } from './components/organization/productbrand/productbrandform/productbrandform.component';
import { productBrandDepartmentListComponent } from './components/organization/productbrand/productbrandlist/productbranddepartmentlist';
import { ExcelService } from './shared/services/excel.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
let count:any = 0;
export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log("loggerCallback : " + message);
}

export function MSALInstanceFactory(appconfig: ConfigurationService): IPublicClientApplication {
  
  // console.log("Inside MSALInstanceFactory : " + appconfig._ApplicationConfig.APP_ClientID.trim());
  let RedirectURL: string;
  if(appconfig._ApplicationConfig.APP_Environment?.trim() === '' || appconfig._ApplicationConfig.APP_Environment?.trim() === undefined){
    appconfig._ApplicationConfig.APP_Environment = localStorage.getItem('RunEnvironment');
  }

  if(appconfig._ApplicationConfig.APP_ClientID?.trim() === '' || appconfig._ApplicationConfig.APP_ClientID?.trim() === undefined){
    appconfig._ApplicationConfig.APP_ClientID = localStorage.getItem('clientId');
  }

  if (appconfig._ApplicationConfig.APP_Environment.trim()?.toLocaleLowerCase() == "prod") {
    RedirectURL = 'https://sds.hmgroup.com/';
  }
  else {
    RedirectURL = 'https://sds-' + appconfig._ApplicationConfig.APP_Environment?.trim() + '.hmgroup.tech/';
  }

  return new PublicClientApplication({
    auth: {

      clientId: appconfig._ApplicationConfig.APP_ClientID?.trim(),
      authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
      redirectUri: RedirectURL,
      //redirectUri: 'http://localhost:4200/',
      postLogoutRedirectUri: '/',

    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}


export function MSALInterceptorConfigFactory(appconfig: ConfigurationService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8/oauth2/v2.0/token', [
    'user.read',
  ]);
  protectedResourceMap.set('/api/', [
    'https://sdsservices-' + localStorage.getItem('RunEnvironment') + '-application.azurewebsites.net/sdsservices.read'
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

export function configurationProviderFactory(configService: ConfigurationService) {
  let loadConfig:any
  if(count === 0){
     loadConfig = configService.loadConfigurationSettings();
     count=1;
  }
  return () => loadConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    MainNavComponent,
    MatConfirmDialogBoxComponent,
    MatSelectAllComponent,
    SubindexComponent,
    SubindexlistComponent,
    SubindexformComponent,
    IndexComponent,
    IndexlistComponent,
    DepartmentlistComponent,
    DepartmentComponent,
    DepartmentformComponent,
    GarmentgroupComponent,
    GarmentgroupformComponent,
    GarmentgrouplistComponent,
    GarmentGroupComponent,
    IndexgroupComponent,
    IndexgroupformComponent,
    IndexgrouplistComponent,
    IndexGroupviewComponent,
    CorporatebrandlistComponent,
    CorporatebrandComponent,
    CorporatebrandformComponent,
    DepartmentsubindexlistComponent,
    DepartmentsubindexformComponent,
    IndexComponent,
    IndexviewComponent,
    IndexformComponent,
    IndexlistComponent,
    DepartmentcustomcustomergroupformComponent,
    DepartmentcustomcustomergrouplistComponent,
    DepartmentmarkupgrouplistComponent,
    DepartmentmarkupgroupformComponent,
    DepartmentviewComponent,
    SubIndexViewComponent,
    CustomergroupComponent,
    CustomergroupformComponent,
    CustomergrouplistComponent,
    DivisionlistComponent,
    DivisionComponent,
    DivisionformComponent,
    SectionComponent,
    SectionformComponent,
    SectionlistComponent,
    MarkupgroupComponent,
    MarkupgroupformComponent,
    MarkupgrouplistComponent,
    MarkupGroupViewComponent,
    CustomDatePipe,
    CustDatePipe,
    SeasonDatePipe,
    ChannelComponent,
    ChannelformComponent,
    ChannellistComponent,
    GeographicalcountryformComponent,
    GeographicalcountryComponent,
    GeographicalcountrylistComponent,
    FiscalcountryformComponent,
    FiscalcountryComponent,
    FiscalcountrylistComponent,
    MatSelectAllComponent,
    ProducttypeandgroupComponent,
    CurrencyComponent,
    VatComponent,
    GbccostComponent,
    VatexceptionComponent,
    MyFilterPipe,
    MultiselectComponent,
    EarlierplanningComponent,
    EarlierplanninglistComponent,
    EarlierplanningformComponent,
    EarlierplanningdepartmentComponent,
    SouthernhemisphereComponent,
    SouthernhemisphereformComponent,
    SouthernhemispherelistComponent,
    SouthernhemispheredepartmentComponent,
    SouthernhemispherdepartmentlistComponent,
    nextlinebrPipe,
    NotFoundComponent,
    DepartmentviewComponent,
    CorporateviewComponent,
    customerGroupviewComponent,
    CurrencyMasterComponent,
    CurrencyformComponent,
    CurrencylistComponent,
    disvisonviewComponent,
    SectionviewComponent,
    CurrencyviewComponent,
    ProuctbrandcomponentComponent,
    ProductbrandformComponent,
    productBrandDepartmentListComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule,
    AgGridModule
  ],
  providers: [
    ApplicationInsightService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ConfigurationService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [ConfigurationService]
    }, { provide: DateAdapter, useClass: AppDateAdapter },
    , { provide: CustomeDateValidator, useClass: CustomeDateValidator },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    UserAccess,
    CanActivateRouteGuard,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalGuard,
    Title,
    CustDatePipe,
    SeasonDatePipe,
    AccountService,
    ExcelService,
    EnvServiceProvider,
    MyFilterPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: configurationProviderFactory,
      multi: true,
      deps: [ConfigurationService]
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [MatConfirmDialogBoxComponent]

})

export class AppModule { }


import { Injectable } from '@angular/core';
import { ApplicationConfig } from '../shared/services/ApplicationConfig';
import { ConfigurationService } from '../shared/services/configuration.service';


@Injectable({
  providedIn: 'root'
})

export class ServiceApi {

  constructor(public _configurationService: ConfigurationService) { }

  public RunEnvironment = localStorage.getItem('RunEnvironment');
  // public RunEnvironment = this._configurationService.RunEnvironment;

  public urlstart = 'https://sdsservices-';

  public API_ENDPOINT = this.urlstart.concat(this.RunEnvironment).concat("-app.azurewebsites.net/api/");


  public CorporateBrandGetAll = this.API_ENDPOINT.concat("CorporateBrand/GetCorporateBrandAll");
  public CorporateBrandGetById = this.API_ENDPOINT.concat("CorporateBrand/GetCorporateBrandId");
  public CorporateBrandCreate = this.API_ENDPOINT.concat("CorporateBrand/createCorporateBrand");
  public CorporateBrandUpdate = this.API_ENDPOINT.concat("CorporateBrand/UpdateCorporateBrand");
  public CorporateBrandDelete = this.API_ENDPOINT.concat("CorporateBrand/DeleteCorporateBrand");

  public IndexGroupGetAll = this.API_ENDPOINT.concat("IndexGroup/GetIndexGroupAll");
  public IndexGroupGetbytId = this.API_ENDPOINT.concat("IndexGroup/GetIndexGroupById?IndexGroupId=");
  public IndexGroupCreate = this.API_ENDPOINT.concat("IndexGroup/CreateIndexGroup");
  public IndexGroupUpdate = this.API_ENDPOINT.concat("IndexGroup/UpdateIndexGroup");

  public CustomerGroupGetAll = this.API_ENDPOINT.concat("CustomerGroup/GetCustomerGroupAll");
  public CustomerGroupById = this.API_ENDPOINT.concat("CustomerGroup/GetCustomerGroupById?customerGroupId=");
  public CustomerGroupCreate = this.API_ENDPOINT.concat("CustomerGroup/CreateCustomerGroup");
  public CustomerGroupUpdate = this.API_ENDPOINT.concat("CustomerGroup/UpdateCustomerGroup");
  public CustomerGroupGetByCBID = this.API_ENDPOINT.concat("CustomerGroup/GetCustomerGroupAll?corporateBrandId=");

  public DivisionGetAll = this.API_ENDPOINT.concat("Division/GetDivisionAll");
  public DivisionCreate = this.API_ENDPOINT.concat("Division/CreateDivision");
  public DivisionUpdate = this.API_ENDPOINT.concat("Division/UpdateDivision");
  public DivisionGetByID = this.API_ENDPOINT.concat("Division/GetDivisionById?divisionId=");

  public IndexGetAll = this.API_ENDPOINT.concat("Index/GetIndexAll");
  public IndexCreate = this.API_ENDPOINT.concat("Index/CreateIndex");
  public IndexUpdate = this.API_ENDPOINT.concat("Index/UpdateIndex");
  public IndexGetByID = this.API_ENDPOINT.concat("Index/GetIndexById?indexId=");
  public IndexCodeGet = this.API_ENDPOINT.concat("Index/GetIndexCodes");
  public IndexCodeGetByCBId = this.API_ENDPOINT.concat("Index/GetIndexAll?corporateBrandId=");
  public IndexGetAllByCBId = this.API_ENDPOINT.concat("IndexGroup/GetIndexGroupAll?corporateBrandId=");
  public SubIndexByIndexId = this.API_ENDPOINT.concat("SubIndex/GetSubIndexAll");

  public SubIndexAll = this.API_ENDPOINT.concat("SubIndex/GetSubIndexAll");
  public SubIndexById = this.API_ENDPOINT.concat("SubIndex/GetSubIndexById?subIndexId=");
  public SubIndexUpdate = this.API_ENDPOINT.concat("SubIndex/UpdateSubIndex");
  public SubIndexCreate = this.API_ENDPOINT.concat("SubIndex/CreateSubIndex");
  public DepartmentGetAll = this.API_ENDPOINT.concat("Department/GetDepartmentByBrand");
  public DepartmentAll = this.API_ENDPOINT.concat("Department/GetDepartmentByBrand?current=current&prec=0&succ=0&pageNumber=1&pageSize=10&SortOrderColumn=LastUpdatedTime&sortByOrder=desc");
  public DepartmentCreate = this.API_ENDPOINT.concat("Department/CreateDepartment");
  public DepartmentUpdate = this.API_ENDPOINT.concat("Department/UpdateDepartment");
  public DepartmentGetByID = this.API_ENDPOINT.concat("Department/GetDepartmentById?departmentId=");


  public DptSubIndexCreate = this.API_ENDPOINT.concat("Department/CreateDepartmentSubIndex");
  public DptSubIndexUpdate = this.API_ENDPOINT.concat("Department/UpdateDepartmentSubIndex");
  public DptSubIndexGetByID = this.API_ENDPOINT.concat("Department/GetDepartmentSubIndexById?departmentSubIndexInfoId=");
  public DptSubIndexDelete = this.API_ENDPOINT.concat("Department/DeleteDepartmentSubIndex");

  public DptCustomCustomerGroupCreate = this.API_ENDPOINT.concat("Department/CreateDepartmentCustomCustomerGroup");
  public DptCustomCustomerGroupUpdate = this.API_ENDPOINT.concat("Department/UpdateDepartmentCustomCustomerGroup");
  public DptCustomCustomerGroupGetByID = this.API_ENDPOINT.concat("Department/GetDepartmentCustomCustomerGroupById?departmentCustomCustomerGroupInfoId=");
  public DptCustomCustomerGroupDelete = this.API_ENDPOINT.concat("Department/DeleteDepartmentCustomCustomerGroup");
  public DptCustomCustomerGroupGetAll = this.API_ENDPOINT.concat("Department/GetDepartmentCustomCustomerGroup");

  public DptMarkupGroupCreate = this.API_ENDPOINT.concat("Department/CreateDepartmentMarkUpGroup");
  public DptMarkupGroupUpdate = this.API_ENDPOINT.concat("Department/UpdateDepartmentMarkUpGroup");
  public DptMarkupGroupGetByID = this.API_ENDPOINT.concat("Department/GetDepartmentMarkUpGroupById?departmentMarkUpGroupInfoId=");
  public DptMarkupGroupDelete = this.API_ENDPOINT.concat("Department/DeleteDepartmentMarkupGroup");
  public DepartmentGetSeasonByRange = this.API_ENDPOINT.concat("Department/GetDepartmentSeasonByRange");
  
  public GarmentGroupGetAll = this.API_ENDPOINT.concat("GarmentGroup/GetGarmentGroupAll");
  public GarmentGroupGetById = this.API_ENDPOINT.concat("GarmentGroup/GetGarmentGroupById?garmentGroupId=");
  public GarmentGroupCreate = this.API_ENDPOINT.concat("GarmentGroup/CreateGarmentGroup");
  public GarmentGroupUpdate = this.API_ENDPOINT.concat("GarmentGroup/UpdateGarmentGroup");
  public GarmentGroupGetDepById = this.API_ENDPOINT.concat("GarmentGroup/GetDepartmentByGarmentGroupId?garmentGroupId=");

  public SectionGetAll = this.API_ENDPOINT.concat("Section/GetSectionAll");
  public SectionCreate = this.API_ENDPOINT.concat("Section/CreateSection");
  public SectionUpdate = this.API_ENDPOINT.concat("Section/UpdateSection");
  public SectionGetByID = this.API_ENDPOINT.concat("Section/GetSectionById?sectionId=");

  public MarkupgroupGetAll = this.API_ENDPOINT.concat("MarkUpGroup/GetMarkUpGroupAll");
  public MarkupgroupCreate = this.API_ENDPOINT.concat("MarkUpGroup/CreateMarkUpGroup");
  public MarkupgroupUpdate = this.API_ENDPOINT.concat("MarkUpGroup/UpdateMarkUpGroup");
  public MarkupgroupGetByID = this.API_ENDPOINT.concat("MarkUpGroup/GetMarkUpGroupById?MarkUpGroupId=");
  public MarkUpGroupDepartmentBySeasonGet = this.API_ENDPOINT.concat("MarkUpGroup/GetMarkUpGroupDepartmentBySeason");
  public GetDepartmentByCorporateBrandId = this.API_ENDPOINT.concat("Department/GetDepartmentByCorporateBrandId");

  public ChannelGetAll = this.API_ENDPOINT.concat("Channel/GetChannelAll");
  public ChannelCreate = this.API_ENDPOINT.concat("Channel/CreateChannel");
  public ChannelUpdate = this.API_ENDPOINT.concat("Channel/UpdateChannel");
  public ChannelGetByID = this.API_ENDPOINT.concat("Channel/GetChannelId?channelId=");

  public CountryGetAll = this.API_ENDPOINT.concat("GeographicalCountry/GetGeographicalCountryAll");
  public CountryCreate = this.API_ENDPOINT.concat("GeographicalCountry/CreateGeographicalCountry");
  public CountryUpdate = this.API_ENDPOINT.concat("GeographicalCountry/UpdateGeographicalCountry");
  public CountryGetByID = this.API_ENDPOINT.concat("GeographicalCountry/GetGeographicalCountryById?geographicalCountryId=");

  public FiscalCountryGetAll = this.API_ENDPOINT.concat("FiscalCountry/GetFiscalCountryAll");
  public FiscalCountryCreate = this.API_ENDPOINT.concat("FiscalCountry/CreateFiscalCountry");
  public FiscalCountryGetById = this.API_ENDPOINT.concat("FiscalCountry/GetFiscalCountryById?fiscalCountryId=");
  public FiscalCountryUpdate = this.API_ENDPOINT.concat("FiscalCountry/UpdateFiscalCountry");
  public FiscalCountryGetCurrency = this.API_ENDPOINT.concat("FiscalCountry/GetCurrencyAll");
  public FiscalGetCustomCustomerGroupALL = this.API_ENDPOINT.concat("Department/GetDepartmentCustomCustomerGroup");
  public FiscalGetTypeOfConstructionALL = this.API_ENDPOINT.concat("FiscalCountry/GetTypeOfConstructionALL");
  public FiscalGetCustomMaterialGroupALL = this.API_ENDPOINT.concat("FiscalCountry/GetCustomMaterialGroupALL");
  public GetProductTypeAndGroupAll = this.API_ENDPOINT.concat("FiscalCountry/GetProductTypeGroupAll");

  public EarlierPlanningGetAll = this.API_ENDPOINT.concat("EarlierPlanning/GetEarlierPlanningBySeason");
  public EarlierPlanningCreate = this.API_ENDPOINT.concat("EarlierPlanning/CreateEarlierPlanning");
  public EarlierPlanningGetEarlierPlanningDepartmentsAndSections = this.API_ENDPOINT.concat("EarlierPlanning/GetEarlierPlanningDepartmentsAndSections");
  public EarlierPlanningGetById = this.API_ENDPOINT.concat("EarlierPlanning/GetEarlierPlanningById?earlierPlanningId=");
  public EarlierPlanningUpdate = this.API_ENDPOINT.concat("EarlierPlanning/UpdateEarlierPlanning");

  public SouthernHemisphereGetAll = this.API_ENDPOINT.concat("SouthernHemisphere/GetSouthernHemisphereBySeason");
  public SouthernHemisphereCreate = this.API_ENDPOINT.concat("SouthernHemisphere/CreateSouthernHemisphere");
  public SouthernHemisphereGetDepartmentsAndSections = this.API_ENDPOINT.concat("SouthernHemisphere/GetSouthernHemisphereDepartmentsAndSections");
  public SouthernHemisphereGetById = this.API_ENDPOINT.concat("SouthernHemisphere/GetSouthernHemisphereById?southernHemisphereId=");
  public SouthernHemisphereUpdate = this.API_ENDPOINT.concat("SouthernHemisphere/UpdateSouthernhemisphere");

  public ProductBrandGetAll =  this.API_ENDPOINT.concat("ProductBrand/GetAllProductBrand");
  public GetProductBrandDepartmentsAndSectionsAndDivisions =  this.API_ENDPOINT.concat("ProductBrand/GetProductBrandDepartmentsAndSectionsAndDivisions");
  public GetProductBrandId =  this.API_ENDPOINT.concat("ProductBrand/GetProductBrandId?productBrandId=");
  public UpdateProductBrand = this.API_ENDPOINT.concat("ProductBrand/UpdateProductBrand");

  public GetCorporateBrandAllBocos = this.API_ENDPOINT.concat("EarlierPlanning/GetCorporateBrandAll");
  public GetSectionAllBocos = this.API_ENDPOINT.concat("EarlierPlanning/GetSectionAll");
  public GetdepartmentAllBocos = this.API_ENDPOINT.concat("EarlierPlanning/GetDepartmentAllBySeason?season=");
  public GetUserInfo= this.API_ENDPOINT.concat("UserAccess/GetUserInfo?upn=");
 
}
